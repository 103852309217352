import React, { Component } from "react"
import { Link } from "gatsby"
import { Breakpoint } from "react-socks";
import OverlayMenu from "react-overlay-menu";

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  toggleMenu() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <>
        <Breakpoint customQuery="(max-width: 960px)">
          <div id="nav-container">
            <div className="nav-open" role="button" aria-label="Open navigation" tabIndex={0} onKeyPress={this.toggleMenu} onClick={this.toggleMenu} />
            <OverlayMenu open={this.state.isOpen} onClose={this.toogleMenu}>
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/portfolio">Portfolio</Link>
              <a href="https://blog.frederon.me/">Blog</a>
              <Link to="/contact">Contact</Link>
              <div className="nav-close" role="button" aria-label="Close navigation" tabIndex={0} onKeyPress={this.toggleMenu} onClick={this.toggleMenu} />
            </OverlayMenu>
          </div>
        </Breakpoint>
        <Breakpoint customQuery="(min-width: 961px)">
          <div id="nav-container">
            <ul className="nav">
              <li className="slide1"></li>
              <li className="slide2"></li>
              <li>
                <Link
                  className={this.props.active === "home" ? "active" : ""}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className={this.props.active === "about" ? "active" : ""}
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  className={this.props.active === "portfolio" ? "active" : ""}
                  to="/portfolio"
                >
                  Portfolio
                </Link>
              </li>
              <li>
                <a href="https://blog.frederon.me/">Blog</a>
              </li>
              <li>
                <Link
                  className={this.props.active === "contact" ? "active" : ""}
                  to="/contact"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </Breakpoint>
      </>
    )
  }
}

export default Menu
